<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="mt-md-5">
          <p
            style="background-color: #DC91B9; padding: 0.5em; border-radius: 4px; font-size: 1em;"
          >
            <strong> Indenizações </strong>
          </p>
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <div
                  class="pa-3 mb-2 my-auto"
                  style="background-color: #F3F3F3;"
                >
                  <p>
                    “Eu trabalhava na minha casa. Fazia corte de cabelo, escova,
                    penteado, manicure, pedicure. Eles falaram que eu não tinha
                    direito porque não tinha sido deslocada, mas quem são eles
                    pra falar o que eu tenho direito ou não? A minha renda era
                    pra minha família. Toda vida, eu fui mãe e pai pro meu
                    filho. Era com o meu dinheiro que eu pagava estudo, comprava
                    roupa e calçado pra ele. Tudo era eu que pagava.
                  </p>
                  <p>
                    Antes do rompimento, minha mãe tinha a vida dela lá na roça
                    e eu tinha a minha aqui. Hoje não, eu tenho que ficar por
                    conta dela e do meu irmão.
                  </p>
                  <p>
                    Outra coisa que eu sinto muita falta é que, desde criança, a
                    gente era acostumada a pescar no rio, na lagoa. Aquela lagoa
                    era do tempo dos nossos bisavós. A gente panhava lambari,
                    acará, traíra, bagre. Lá tinha peixe criado, de muito tempo.
                    Agora acabou (Marlene Agostinha, atingida de Pedras, 2018).”
                  </p>
                </div>

                <p>
                  Esse relato feito por uma mulher atingida de Pedras ao jornal
                  A Sirene (CASTRO et al., 2018) revela que as mulheres
                  atingidas parecem não ter direitos para a Fundação Renova
                  quando se trata de indenizações pela perda de seu trabalho e
                  renda. Diminuindo o seu papel na sociedade, as empresas atuam
                  da seguinte forma, conforme relatado por Vera Lúcia Aleixo,
                  atingida de Gesteira:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;">
            “A empresa me considera dependente do meu marido e, para ela, tenho
            direito a receber 20% do que ele recebe. Eu não vivia de
            porcentagem, eu tinha meu salário e é um absurdo eu não ser
            reconhecida até hoje. Eu tinha um salão domiciliar, já tinha toda a
            estrutura, e era o único em Gesteira. Fazia escova, hidratação,
            relaxamento, corte, tudo. Isso está no meu cadastro, mas eles
            disseram que não conheciam a minha história. Não se interessaram,
            né? Porque estava tudo lá (CASTRO et al., 2018).”
          </p>
        </v-col>
        <v-col
          cols="10"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_11.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Pinto. Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  No cadastramento realizado pela Cáritas, em que a composição
                  do Núcleo Familiar e definição da Referência é feita por
                  autodeclaração dos próprios atingidos e atingidas, observa-se
                  que, embora o número de mulheres e homens cadastrados seja
                  praticamente o mesmo, isso não se reflete na distribuição por
                  gênero das referências familiares: pouco mais de 60% dos
                  responsáveis pelos NFs são do sexo masculino, ou seja, há
                  cerca de 904 núcleos cujos responsáveis são homens em oposição
                  a apenas 589 em que a referência é do sexo feminino.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_12.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Cáritas Brasileira Regional Minas Gerais, 2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Muitas vezes a equipe da Cáritas identificou durante o
                  cadastramento em núcleos familiares cujas referências são os
                  homens, a ausência da esposa e/ou filhos dentre os membros.
                  Houve ocasião do atingido apresentar a documentação
                  comprobatória de matrimônio para constar no dossiê, bem como
                  responder afirmativamente à questão de que estava casado no
                  momento do rompimento e na data de atendimento, mas não
                  realizava a inserção da esposa no núcleo familiar. Via de
                  regra, as mulheres acreditavam estar sendo consideradas junto
                  aos cônjuges.
                </p>
                <p>
                  Ainda que respeitando o direito à autodeclaração, sempre que
                  os assessores se atentaram para essa situação, era sugerido à
                  referência que reconsiderasse a composição familiar. Também se
                  observou, durante as oficinas de Cartografias Sociais, a
                  tendência à desconsideração das atividades que costumam ser
                  realizadas pelas mulheres quando apenas homens participavam.
                  Tal constatação derivou num esforço de estimular a presença de
                  todo o núcleo familiar para a realização das etapas, evitando
                  assim que o registro e levantamento das perdas fosse
                  prejudicado.
                </p>
                <p>
                  Houve ainda casos em que os maridos usaram o cadastro para
                  coagir ou punir as esposas com a ameaça de retirá-las do
                  núcleo familiar ou tentando negar a elas o acesso ao dossiê.
                  Ademais, ocorre que quando a pessoa referência do núcleo é o
                  homem, não é raro que a esposa e os filhos encontrem
                  dificuldades para acessar o dinheiro da indenização. A
                  atingida explica: se a mulher precisar do dinheiro, terá que
                  pedir autorização ao marido para usufruir da indenização.
                </p>
                <p>
                  Em atendimento a uma mulher atingida de Paracatu de Baixo pela
                  assessoria jurídica, ela relata<sup>1</sup> seu incômodo e
                  angústia em razão do formato de indenização que é ofertada
                  pela Fundação Renova. Frequentemente, o montante maior da
                  indenização, como os bens domésticos e os cultivos, por
                  exemplo, que têm por característica ser comum a todas(os)
                  as(os) integrantes do núcleo, é depositado na conta da pessoa
                  referência do núcleo familiar<sup>2</sup>. Apenas danos
                  individuais, como danos morais ou perda de objetos pessoais,
                  são depositados individualmente.
                </p>
                <p>
                  Essa é uma situação que gera incertezas e inseguranças às
                  mulheres, principalmente nos casos em que há violência
                  doméstica ou de divórcio que implica em uma partilha de bens.
                  Por isso, a atingida diz que a indenização pelos bens comuns
                  da família deveria ser dividida igualmente entre homens e
                  mulheres, para que não sejam reproduzidas hierarquias
                  injustificadas dentro da família.
                </p>
                <p>
                  Dessa forma, é possível perceber que as mineradoras impõem
                  dinâmicas hierárquicas na organização familiar, concedendo ao
                  homem o lugar de privilégio e de poder que pode minar a
                  independência das mulheres e o seu direito à indenização.
                </p>
                <p>
                  É esse o relato trazido no depoimento de Carolina Rodrigues,
                  atingida de Barra Longa, ao jornal A Sirene (RODRIGUES, 2019),
                  que traz em sua fala de forma inconteste o que é o machismo
                  vivenciado no território atingido:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Fui dada como dependente do meu pai sendo que eu também pescava e
            fui atingida. Se fosse um homem ocupando a minha posição, seria
            diferente. Se fosse um menino que pescasse com o pai desde novo, ele
            teria mais chances de ser reconhecido do que eu porque é um ambiente
            machista. Eu estou buscando reconhecimento porque sou uma pessoa
            completamente diferente do meu pai e não vou mais aceitar ser dada
            como dependente dele (Carolina Rodrigues, mulher pescadora atingida
            de Barra Longa, em 2019).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Assim, a violência contra as mulheres atingidas persiste de
                  várias formas, dentre elas o não reconhecimento dos trabalhos
                  tidos como “de homem” para fins de indenização. Ao que parece,
                  a Fundação Renova atribui às mulheres determinados trabalhos e
                  lhes nega a possibilidade de reconhecimento de trabalhos
                  produtivos e/ou braçais. Além disso, ao negar o reconhecimento
                  do trabalho e a indenização às mulheres, a Fundação Renova
                  acaba por valorizar mais a voz e o trabalho declarado pelo
                  homem. Diferentemente do que a Fundação Renova entende, as
                  mulheres são grandes provedoras de seus lares por meio de
                  atividades diversas nas comunidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_13.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Pinto. Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A situação denunciada por Carolina e por muitas outras
                  atingidas revela o descaso das empresas rés com a vida das
                  mulheres. Mais do que isso: destaca a prática institucional da
                  Fundação Renova de negar às mulheres o reconhecimento da
                  posição de atingidas e de trabalhadoras.
                </p>
                <p>
                  É o que se pode dizer do caso de uma mulher atingida que
                  procurou a reparação dos danos por meio da FNE. A mulher
                  exercia a posse de um terreno que a lama destruiu. Em seus
                  relatos, ela diz sentir muita falta de pescar no rio Gualaxo
                  do Norte, pois hoje não pode pescar mais. Ela conta que sente
                  falta de ter o seu terreno para cuidar das plantações, além de
                  poder desfrutar de sua colheita. Conforme sua fala registrada
                  em Tomada de Termo:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “[...] antes do rompimento da barragem me sentia feliz. Antes podia
            ir ao terreno em Pedras e ver tudo verdinho. Hoje em dia, chego no
            terreno e vejo tudo destruído. Antes do rompimento, era lindo ver as
            plantações de feijão na baixada, por exemplo, mas hoje em dia me
            sinto aborrecida quando vejo o terreno.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_14.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Helena. Jornal A Sirene, 2017.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A atingida relata que as despesas de sua família aumentaram
                  significativamente em razão da falta dos alimentos que ela
                  mesma cultivava junto de seu marido. Essa mulher, que dividia
                  todas essas tarefas de plantio e cuidado dos animais com o
                  esposo, e que teve contato com a lama tóxica de rejeitos,
                  recebeu uma negativa da Fundação Renova de pagar sua
                  indenização, embora seu marido tenha recebido uma proposta
                  indenizatória.
                </p>
                <p>
                  Seriam as diretrizes de indenização da Fundação Renova
                  discriminatórias de gênero, já que reconhecem os danos do
                  homem e não reconhecem os danos morais e materiais suportados
                  pela mulher?
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Ora, essa suposição e questionamento não é infundada. Em
                  muitos casos, a Fundação Renova parece utilizar de estratégias
                  que reduzem a autonomia própria das mulheres e violam sua
                  dignidade para lhes negar direitos. A tática de invisibilizar
                  o trabalho realizado por mulheres, deixando de reconhecer as
                  tarefas que são típicas da lida rural como indenizáveis, é
                  abusiva e arbitrária. Com isso, a Fundação Renova não promove
                  uma reparação que seja fiel aos modos de vida das mulheres
                  como eram antes do rompimento, ou seja, como mantenedoras da
                  casa; mas sim as coloca como inferiores ao marido no aspecto
                  laboral.
                </p>
                <p>
                  Em sua fala, a atingida também narra os casos de perseguição e
                  de preconceito com as pessoas atingidas na cidade de Mariana.
                  Segundo relatou na Tomada de Termo:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “[...] após o rompimento da barragem, fiquei muito preocupada com os
            amigos que foram atingidos pela barragem e tiveram que se mudar pra
            Mariana. Um dos meus amigos de Paracatu começou a morar em Águas
            Claras, porque não se adaptou a vida em Mariana, ele hoje mora
            sozinho em uma casa em Águas Claras. Eu sinto muita pena dele e dos
            amigos que foram obrigados a morar em Mariana. Eu tive contato com a
            lama de rejeitos da barragem.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Sendo assim, uma vez que tais violações aos direitos das
                  mulheres atingidas são praticadas de forma recorrente pelas
                  empresas Samarco, Vale e BHP Billiton, percebe-se o que parece
                  ser uma tática de deslegitimação de direitos, promovida pelas
                  responsáveis pelo crime, e que atualiza e reforça mecanismos
                  de opressão e machismo contra essas mulheres.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> O relato foi feito em reunião de Entrega de Parecer
              Jurídico e Documento de Valoração, realizada entre a assessoria
              jurídica e a família, motivo pelo qual não há documentos públicos
              em que haja menção a esse diálogo.
            </p>
            <p>
              <sup>2</sup> O Cadastro aplicado em Mariana garantiu que o mesmo
              fosse organizado por núcleos familiares de forma que os atingidos
              e atingidas agrupam-se conforme o seu sentimento de pertencimento
              ao núcleo familiar para responder aos instrumentos, em contraponto
              ao Cadastro anterior que estava vinculado à propriedade. Nota-se
              também a observação do direito à autodeclaração daqueles que são
              cadastrados, uma vez que todos aqueles que se reconhecem como
              atingidas e atingidos têm resguardado o direito de participar do
              Cadastramento e definir a composição do núcleo familiar no qual
              acredita fazer parte. O Núcleo Familiar, constituído em diferentes
              formatos e não necessariamente um núcleo familiar correspondente a
              um domicílio, é a unidade utilizada no cadastramento. A própria
              família define a composição dos membros que o integrarão e um
              deles é a referência, que passa a responder junto à Cáritas para a
              realização das etapas. Todos os membros, no entanto, são ouvidos
              em algum momento do Cadastro e têm acesso ao dossiê familiar,
              documento que reúne o que foi produzido no cadastramento.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },
        {
          text: "Indenizações",
          href: "conteudos_tematicos_indenizacoes",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }

  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image3::before {
    display: none;
  }

  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }

  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
